var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("data-management-header", {
        attrs: { workflowName: _vm.workflowName, tabsItems: _vm.tabsItems },
      }),
      _c("listing-component", {
        attrs: {
          type: _vm.listingType,
          "module-name": _vm.moduleName,
          headers: _vm.headers,
          "overridden-columns": _vm.overriddenColumns,
          "show-delete-action": "",
        },
        scopedSlots: _vm._u([
          {
            key: "triggering_file",
            fn: function ({ item: { id, triggering_file } }) {
              return [
                _c(
                  "router-link",
                  { attrs: { to: { name: _vm.routeName, params: { id } } } },
                  [
                    _c("span", { staticClass: "font-weight-medium" }, [
                      _vm._v(_vm._s(triggering_file)),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "dag_execution_date",
            fn: function ({ item: { dag_execution_date } }) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("moment")(dag_execution_date, "YYYY/MM/DD - HH:mm")
                    ) +
                    " "
                ),
              ]
            },
          },
          {
            key: "duration",
            fn: function ({ item: { duration } }) {
              return [
                _vm._v(" " + _vm._s(_vm.getFormattedDuration(duration)) + " "),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }