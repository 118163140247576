
import { Component, Mixins } from 'vue-property-decorator';
import HeaderInfosMixin from '../header-infos';
import RunCollectionMixin from '@/mixins/data-operations/collection/run-collection-mixin';
import { fileUtilitiesRunsLight } from '@/store/modules/easy-firestore/file-utilities-runs-light';
import { FILE_UTILITIES_RUNS_ITEM } from '@/constants/router/routes-names';
import {
	CONFIGURATION_ID,
	DAG_EXECUTION_DATE,
	DURATION,
	ENVIRONMENT,
	GCS_BUCKET,
	RUN_CLIENT_VERSION,
	STATUS,
	TRIGGERING_FILE,
} from '@/constants/data-operations/listing/header-items';

@Component
export default class FileUtilitiesRunsListingView extends Mixins(HeaderInfosMixin, RunCollectionMixin) {
	moduleName: string = fileUtilitiesRunsLight.moduleName;
	overriddenColumns: string[] = ['triggering_file', 'dag_execution_date', 'duration'];

	get routeName() {
		return FILE_UTILITIES_RUNS_ITEM;
	}

	get headers() {
		return [
			ENVIRONMENT,
			{
				...RUN_CLIENT_VERSION,
				value: 'configuration_context.configuration.client_version',
			},
			{
				...CONFIGURATION_ID,
				value: 'configuration_context.configuration.configuration_id',
				width: 300,
			},
			{
				...GCS_BUCKET,
				value: 'configuration_context.configuration.gcs_bucket',
				width: 200,
			},
			TRIGGERING_FILE,
			STATUS,
			DAG_EXECUTION_DATE,
			DURATION,
		];
	}
}
